import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const config = {
    apiKey: "AIzaSyDRhzKcVcIho6ZDzdZz-vcueMjb-5mYfDY",
    authDomain: "gopanda-b26fa.firebaseapp.com",
    databaseURL: "https://gopanda-b26fa.firebaseio.com",
    projectId: "gopanda-b26fa",
    storageBucket: "gopanda-b26fa.appspot.com",
    messagingSenderId: "137340133939",
    appId: "1:137340133939:web:a0bc250d149abe933e3946",
    measurementId: "G-SEPLK2E627"
};


if (!firebase.apps.length) {
    firebase.initializeApp(config);
}


export default firebase;
export const database = firebase.database().ref();
export const firebaseAuth = firebase.auth();